import { Controller } from "@suttyweb/stimulus";

export default class extends Controller {
  invalidFeedback(event) {
    const input = event.target;
    const formGroup = this.findFormGroup(input);
    const invalidFeedback = this.findInvalidFeedback(formGroup);
    const validFeedback = this.findValidFeedback(formGroup);

    validFeedback.classList.add("d-none");
    validFeedback.classList.remove("d-inline");

    invalidFeedback.classList.add("d-inline");
    invalidFeedback.classList.remove("d-none");

    formGroup.classList.remove("border-gray-dark", "hover-border-black");
    formGroup.classList.add("border-red", "hover-border-red");
  }

  reportValidity(event) {
    const input = event.target;

    if (!input.checkValidity()) return;

    const formGroup = this.findFormGroup(input);
    const invalidFeedback = this.findInvalidFeedback(formGroup);
    const validFeedback = this.findValidFeedback(formGroup);

    invalidFeedback.classList.add("d-none");
    invalidFeedback.classList.remove("d-inline");

    validFeedback.classList.add("d-inline");
    validFeedback.classList.remove("d-none");

    formGroup.classList.remove("border-red", "hover-border-red");
    formGroup.classList.add("border-gray-dark", "hover-border-black");
  }

  findFormGroup(input) {
    let formGroup = input;

    while (!formGroup?.classList?.contains("form-group")) {
      formGroup = formGroup.parentElement;
    }

    return formGroup;
  }

  findInvalidFeedback(formGroup) {
    return formGroup.querySelector(".invalid-feedback");
  }

  findValidFeedback(formGroup) {
    return formGroup.querySelector(".valid-feedback");
  }

  submit(event) {
    event?.preventDefault();

    const detail = Object.fromEntries((new FormData(event.target)).entries());

    window.dispatchEvent(new CustomEvent("buildServiceToggle", { detail }));
  }

  reset(event) {
    const formGroups = this.element.querySelectorAll(".form-group");

    for (const formGroup of formGroups) {
      const invalidFeedback = this.findInvalidFeedback(formGroup);
      const validFeedback = this.findValidFeedback(formGroup);

      validFeedback.classList.add("d-none");
      validFeedback.classList.remove("d-inline");

      invalidFeedback.classList.add("d-none");
      invalidFeedback.classList.remove("d-inline");

      formGroup.classList.add("border-gray-dark", "hover-border-black");
      formGroup.classList.remove("border-red", "hover-border-red");
    }
  }
}
