import { Controller } from "@suttyweb/stimulus";

export default class extends Controller {
  static targets = ["href"];
  static values = {
    feedback: {
      type: String,
      default: "Copied!",
    },
  };

  async copy() {
    let href;

    if (this.hasHrefTarget) href = this.hrefTarget.innerText.trim();

    await navigator.clipboard.writeText(href || window.location.href);

    window.dispatchEvent(
      new CustomEvent("floatingAlert", { detail: { content: this.feedbackValue } })
    );
  }
}
