import { Controller } from "@suttyweb/stimulus";

export default class extends Controller {
  static targets = ["text", "image"];
  static values = {
    interval: Number,
    timeout: {
      type: Number,
      default: 1,
    },
    duration: {
      type: Number,
      default: 5,
    },
    current: {
      type: Number,
      default: 0,
    },
  };

  connect() {
    this.intervalValue = setInterval(() => {
      const currentText = this.textTargets[this.currentValue];

      this.currentValue = this.currentValue + 1;

      let nextText = this.textTargets[this.currentValue];

      if (!nextText) {
        this.currentValue = 0;
        nextText = this.textTargets[this.currentValue];
      }

      this.hide(currentText);
      setTimeout(() => this.show(nextText), this.timeoutValue * 1000);

      if (!this.hasImageTarget) return;

      const currentImage = this.imageTargets[this.currentValue - 1];
      const nextImage = this.imageTargets[this.currentValue];

      if (currentImage) this.hide(currentImage);
      setTimeout(() => this.show(nextImage), this.timeoutValue * 1000);

    }, this.durationValue * 1000);
  }

  disconnect() {
    clearInterval(this.intervalValue);
  }

  hide(target) {
    target.classList.remove("o-5");
    target.classList.add("o-0");

    setTimeout(() => {
      target.hidden = true;
    }, this.timeoutValue * 1000);
  }

  show(target) {
    target.hidden = false;

    setTimeout(() => {
      target.classList.remove("o-0");
      target.classList.add("o-5");
    }, 100);
  }
}
