import { Controller } from "@suttyweb/stimulus";
import Swiper, { Navigation } from "swiper";

export default class extends Controller {
  static targets = ["swiper", "next", "prev"];

  connect() {
    const options = {
      slidesPerView: "auto",
      modules: [],
    };

    if (this.hasNextTarget && this.hasPrevTarget) {
      options.modules.push(Navigation);

      options.navigation = {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      };
    }

    console.log(options);

    this.swiper = new Swiper(this.swiperTarget, options);
  }
}
