import { Controller } from "@suttyweb/stimulus";

// window.dispatchEvent(
//   new CustomEvent("floatingAlert", { detail: { content: "¡Hola, usuarix!" } })
// );
export default class extends Controller {
  static targets = ["content"];

  show({ detail: { content } }) {
    this.contentTarget.innerText = content;
    this.set(true);

    if (this.interval) clearTimeout(this.interval);

    this.interval = setTimeout(() => {
      this.set(false);
      this.interval = null;
    }, 3000);
  }

  set(show) {
    if (show) {
      this.element.classList.remove("hide");
      this.element.classList.add("show");
    } else {
      this.element.classList.add("hide");
      this.element.classList.remove("show");
    }
  }
}
