import { Controller } from "@suttyweb/stimulus";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import zh from "javascript-time-ago/locale/zh";

TimeAgo.addLocale(en);
TimeAgo.addLocale(zh);

const QRCode = require("@keeex/qrcodejs-kx");

export default class extends Controller {
  static targets = [
    "form",
    "pkgName",
    "latestBuildProgress",
    "checksum",
    "versionName",
    "hostName",
    "build",
    "process",
    "error",
    "success",
    "unblockedUrl",
    "downloadUrl",
    "qrDownloadUrl",
    "qr",
    "appName",
    "appIcon",
    "createdAt",
    "updatedAt",
    "homePage",
    "toggler",
    "background",
  ];

  static values = {
    pkgName: String,
    checksum: String,
    versionName: String,
    interval: Number,
    beaconListener: {
      type: Boolean,
      default: false,
    },
    hostName: String,
    unblockedUrl: String,
    unblockedBaseUrl: String,
    downloadUrl: String,
    latestBuildProgress: {
      type: Number,
      default: 0,
    },
    buildService: {
      type: String,
      default: "https://appmaker.greatfire.org",
    },
    appName: String,
    appIcon: String,
    createdAt: String,
    updatedAt: String,
    homePage: String,
    qrSize: {
      type: Number,
      default: 200,
    },
  };

  toggle({ detail }) {
    for (const field in detail) {
      this.formTarget.elements[field].value = detail[field];
    }

    if (!this.hasTogglerTarget) return;

    this.togglerTarget.checked = !this.togglerTarget.checked;
  }

  downloadUrlValueChanged(downloadUrl) {
    if (!downloadUrl) return;

    for (const downloadUrlTarget of this.downloadUrlTargets) {
      downloadUrlTarget.href = downloadUrl;
    }

    const options = {
      text: downloadUrl,
      width: this.qrSizeValue,
      height: this.qrSizeValue,
      colorDark: "#000000",
      colorLight: "#FFFFFF",
    };

    for (const qrTarget of this.qrTargets) {
      qrTarget.innerHTML = "";
      new QRCode(qrTarget, options);
    }

    if (this.hasQrTarget && this.hasQrDownloadUrlTarget) {
      const qrImage = this.qrTarget.querySelector("img");

      setTimeout(() => {
        for (const qrDownloadUrlTarget of this.qrDownloadUrlTargets) {
          qrDownloadUrlTarget.setAttribute("download", `${this.pkgNameValue}.qr.png`);
          qrDownloadUrlTarget.setAttribute("href", qrImage?.src);
        }
      }, 300);
    }
  }

  unblockedUrlValueChanged(unblockedUrl) {
    for (const unblockedUrlTarget of this.unblockedUrlTargets) {
      const attribute = (unblockedUrlTarget.tagName === "A" ? "href" : "innerText");
      unblockedUrlTarget[attribute] = unblockedUrl;
    }
  }

  hostNameValueChanged(hostName) {
    for (const hostNameTarget of this.hostNameTargets) {
      hostNameTarget.innerText = hostName;
      hostNameTarget.href = this.homePageValue;
    }
  }

  pkgNameValueChanged(pkgName, oldPkgName) {
    if (pkgName === oldPkgName) return;

    for (const pkgNameTarget of this.pkgNameTargets) {
      pkgNameTarget.innerText = pkgName;
    }
  }

  checksumValueChanged(checksum, oldPkgName) {
    for (const checksumTarget of this.checksumTargets) {
      checksumTarget.innerText = checksum;
    }
  }

  latestBuildProgressValueChanged(latestBuildProgress, oldLatestBuildProgress) {
    if (latestBuildProgress <= oldLatestBuildProgress) return;

    for (const latestBuildProgressTarget of this.latestBuildProgressTargets) {
      this.updateProgressBar(latestBuildProgressTarget, latestBuildProgress);
    }
  }

  versionNameValueChanged(versionName, oldPkgName) {
    for (const versionNameTarget of this.versionNameTargets) {
      versionNameTarget.innerText = versionName;
    }
  }

  appNameValueChanged(appName, oldPkgName) {
    for (const appNameTarget of this.appNameTargets) {
      appNameTarget.innerText = appName;
    }
  }

  appIconValueChanged(appIcon, oldPkgName) {
    for (const appIconTarget of this.appIconTargets) {
      appIconTarget.src = appIcon;
    }
  }

  createdAtValueChanged(createdAt, oldPkgName) {
    for (const createdAtTarget of this.createdAtTargets) {
      createdAtTarget.innerText = createdAt;
    }
  }

  get currentLocale() {
    return document.querySelector("html").lang.split(/[-_]/)[0] || "en";
  }

  updatedAtValueChanged(updatedAt) {
    let date = updatedAt.trim().length == 0 ? new Date() : new Date(updatedAt);

    if (isNaN(date)) {
      date = new Date();
      console.error("Couldn't parse date", updatedAt);
    }

    const timeAgo = new TimeAgo(this.currentLocale);
    const formattedDate = timeAgo.format(date);
    const isoDate = date.toISOString();

    for (const updatedAtTarget of this.updatedAtTargets) {
      updatedAtTarget.setAttribute("datetime", isoDate);
      updatedAtTarget.innerText = formattedDate;
    }
  }

  homePageValueChanged(homePage, oldPkgName) {
    if (!homePage) return;

    const url = new URL(homePage);

    this.hostNameValue = url.hostname;

    for (const homePageTarget of this.homePageTargets) {
      homePageTarget.innerText = homePage;
    }
  }

  updateProgressBar(progressBar, progress) {
    progressBar.style.width = `${progress}%`;
    progressBar.ariaValuenow = progress;
  }

  error(event) {
    event?.preventDefault();
    event?.stopPropagation();

    if (this.hasBackgroundTarget) this.backgroundTarget.classList.add(this.backgroundTarget.dataset.class);

    this.hideGracefully(this.processTargets);
    this.hideGracefully(this.successTargets);
    this.hideGracefully(this.buildTargets);
    this.showGracefully(this.errorTargets);

    clearInterval(this.intervalValue);
    this.latestBuildProgressValue = 0;

    for (const latestBuildProgressTarget of this.latestBuildProgressTargets) {
      this.updateProgressBar(latestBuildProgressTarget, 0);
    }
  }

  cancel(event) {
    event?.preventDefault();
    event?.stopPropagation();

    if (this.hasBackgroundTarget) this.backgroundTarget.classList.add(this.backgroundTarget.dataset.class);

    this.hideGracefully(this.processTargets);
    this.hideGracefully(this.errorTargets);
    this.hideGracefully(this.successTargets);
    this.showGracefully(this.buildTargets);

    clearInterval(this.intervalValue);
    this.latestBuildProgressValue = 0;

    for (const latestBuildProgressTarget of this.latestBuildProgressTargets) {
      this.updateProgressBar(latestBuildProgressTarget, 0);
    }
  }

  showGracefully(elements, hide = false) {
    for (const element of elements) {
      element.style.zIndex = "";

      if (hide) element.hidden = false;

      setTimeout(() => {
        element.classList.add("o-5");
        element.classList.remove("o-0");
      }, 300);
    }
  }

  hideGracefully(elements, hide = false) {
    for (const element of elements) {
      element.classList.remove("o-5");
      element.classList.add("o-0");
      element.style.zIndex = "-1";

      if (hide) setTimeout(() => element.hidden = true, 300);
    }
  }

  get broadcastChannel() {
    if (!("BroadcastChannel" in window)) return;

    if (!this._broadcastChannel) this._broadcastChannel = new BroadcastChannel("app-maker");

    return this._broadcastChannel;
  }

  async create(event) {
    event.preventDefault();
    event.stopPropagation();

    this.hideGracefully(this.buildTargets);
    this.hideGracefully(this.errorTargets);
    this.showGracefully(this.processTargets);

    const method = "POST";
    const body = new FormData(this.formTarget);
    const headers = { "x-app-maker": "x-app-maker" };
    const mode = "cors";
    const redirect = "manual";

    try {
      const response = await fetch(this.buildServiceValue, { method, body, mode, redirect, headers });
      const json = await response.json();

      this.setValuesFromJson(json);
    } catch(e) {
      console.error(e);

      setTimeout(() => {
        this.hideGracefully(this.processTargets);
        this.showGracefully(this.errorTargets);
      }, 1000);
      return;
    }

    this.latestBuildProgressValue = 0;

    let latestBuildProgressValue = this.latestBuildProgressValue;

    this.intervalValue = setInterval(() => {
      window.requestAnimationFrame(async () => {
        latestBuildProgressValue = latestBuildProgressValue + 1;
        this.latestBuildProgressValue = latestBuildProgressValue;

        try {
          await this.buildServiceClient();

          if (latestBuildProgressValue < this.latestBuildProgressValue) {
            latestBuildProgressValue = this.latestBuildProgressValue;
          }

          if (this.latestBuildProgressValue < 100) return;
          if (this.hasBackgroundTarget) this.backgroundTarget.classList.remove(this.backgroundTarget.dataset.class);

          this.hideGracefully(this.processTargets);
          this.showGracefully(this.successTargets);
          this.formTarget.reset();

          this.downloadUrl = this.pkgNameValue;
          this.unblockedUrl = this.pkgNameValue;
          window.sessionStorage.pkgName = this.pkgNameValue;

          clearInterval(window.sessionStorage.beaconValue);

          window.sessionStorage.beaconValue = setInterval(() => {
            this.broadcastChannel?.postMessage(this.pkgNameValue);
          }, 1000);

          clearInterval(this.intervalValue);
        } catch(e) {
          console.error(e);
          this.error();
        }
      });
    }, 1000);
  }

  connect() {
    const url = new URL(window.location.href);

    if (!this.pkgNameValue) {
      if (url.hash) {
        this.pkgNameValue = url.hash.split("#")[1];
      } else {
        this.recoverFromStorage();
      }
    }

    if (this.beaconListenerValue) {
      this.receivedMessage = this.receivedMessage.bind(this);
      this.broadcastChannel?.addEventListener("message", this.receivedMessage);
    };

    this.downloadUrl = this.pkgNameValue;
    this.unblockedUrl = this.pkgNameValue;
    this.buildServiceClient();
  }

  disconnect() {
    this.cancel();
    clearInterval(window.sessionStorage.beaconValue);
    this.broadcastChannel?.close();
  }

  close(event) {
    this.hideGracefully([this.element], true);
  }

  receivedMessage(event) {
    if (window.sessionStorage.pkgName === event.data) return;

    window.sessionStorage.pkgName = event.data;
    window.dispatchEvent(new CustomEvent("notification"));
  }

  recoverFromStorage(event = undefined) {
    const pkgNameFromStorage = window.sessionStorage.pkgName;

    if (!pkgNameFromStorage) return;

    this.pkgNameValue = pkgNameFromStorage;
    this.showGracefully([this.element], true);

    if (!event) return;

    this.downloadUrl = this.pkgNameValue;
    this.buildServiceClient();
  }

  showNotificationAfterClosing(event) {
    if (event.target.checked) return;
    if (!this.pkgNameValue) return;

    window.dispatchEvent(new CustomEvent("notification"));
  }

  // {"pkg_name":"nl.sutty","latest_build_progress":60,"checksum":"","version_name":"5.1.2.au"}
  async buildServiceClient() {
    if (!this.pkgNameValue) return;

    const response = await fetch(`${this.buildServiceValue}/api/build/${this.pkgNameValue}`);
    const json = await response.json();

    this.setValuesFromJson(json);
  }

  setValuesFromJson(json) {
    for (const key in json) {
      this[`${this.camelize(key)}Value`] = json[key];
    }
  }

  set unblockedUrl(pkgName) {
    const parsedUrl = new URL(window.location.href);
    parsedUrl.pathname = this.unblockedBaseUrlValue;
    parsedUrl.hash = pkgName;

    this.unblockedUrlValue = parsedUrl.toString();
  }

  set downloadUrl(pkgName) {
    const parsedUrl = new URL(this.buildServiceValue);
    parsedUrl.pathname = `/media/apks/${pkgName}.apk`;

    this.downloadUrlValue = parsedUrl.toString();
  }

  // Copied from @hotwired/stimulus;
  camelize(value) {
    return value.replace(/(?:[_-])([a-z0-9])/g, (_, char) => char.toUpperCase());
  }
}
