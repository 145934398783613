import { Controller } from "@suttyweb/stimulus";

export default class extends Controller {
  static targets = ["element"];

  // si es el sistema operativo, queremos mostrar u ocultar
  // si no es el sistema operativo, queremos lo contrario
  elementTargetConnected(elementTarget) {
    const osFamily = elementTarget.dataset.osFamily;
    const actionForDevice = elementTarget.dataset.actionForDevice || "show";

    const isDevice = (window.device.os.name.toLowerCase() === osFamily.toLowerCase());
    const shouldShow = (actionForDevice === "show");
    const show = (isDevice ? shouldShow : !shouldShow);

    elementTarget.hidden = !show;

    if (!show) elementTarget.classList.remove("d-block", "d-flex");
  }
}
